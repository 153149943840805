import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";

function ModuleFive() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Reflection"
                time="20 Minutes"
                exercise={false}
                points={["Building a plan.", 
                "Refining your goals.", 
                "Preparing for action & Getting the results you need."]}
            />

            <div className="body-container">
            <div className="video-container">
            {/* Insert links to videos below */}
            <YoutubeEmbed embedId="N5HynfDCqjA" />
            <YoutubeEmbed embedId="s0qmGISS_kw" />
            <YoutubeEmbed embedId="ZH8Nxr0r8rM" />
            <YoutubeEmbed embedId="rq4eDpeerpo" />
            </div>

            <div className="menu-arrow-container">
            {/* Links to next and previous modules, if none put {null} */}
            <Arrows next="/module-6" previous="/module-4b" />

            {/* Update menu in IntroMenu.jsx */}
            <IntroMenu />
            </div>
            </div>

        </div>
    )
}

export default ModuleFive
