import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";

function ModuleFourA() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Where Am I Now - MTQ"
                time="30 Minutes"
                exercise={false}
                points={["An introduction to the MTQPlus. How to complete it, a summary of the reports & how to use these for initial understanding.", 
                "Understanding the MTQ reports. The scoring system, understanding sten scores & ipsative v normative assessments.", 
                "The reports in detail. Understanding the narrative, sten score combinations, outliers and their impact & indictors of development areas."]}
            />

            <div className="body-container">
                <div className="video-container">
                    {/* Insert links to videos below */}
                    <YoutubeEmbed embedId="0MBnmg6zCQY" />
                    <YoutubeEmbed embedId="ihRRtunLhe0" />
                    <YoutubeEmbed embedId="kDF8XjzCBvI" />
                    <YoutubeEmbed embedId="vFHd_ZWpK5o" />
                </div>

                <div className="menu-arrow-container">
                    {/* Links to next and previous modules, if none put {null} */}
                    <Arrows next="/module-4b" previous="/module-3" />

                    {/* Update menu in IntroMenu.jsx */}
                    <IntroMenu />
                </div>
            </div>

        </div>
    )
}

export default ModuleFourA
