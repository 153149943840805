import React from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa'
import {Link} from "react-router-dom"

function ScrollToTop() {
  window.scrollTo(0, 0)
}

const Arrows = ({ next, previous }) => (
  <div onClick={ScrollToTop} className="arrows">
    {next &&
    <Link to={next} className="arrow-btn">
      <p>Next</p>
      <FaArrowAltCircleRight />
    </Link>
    }

    {previous &&
    <Link to={previous} className="arrow-btn">
      <p>Previous</p>
      <FaArrowAltCircleLeft />
    </Link>
    }
  </div>
);

export default Arrows;