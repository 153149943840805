import React from 'react'
import { FaSignInAlt, FaDoorOpen } from 'react-icons/fa'
import logo from '../images/logo512.png'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux'
import './Dasboard.css'

function Dashboard() {
    const { user } = useSelector((state) => state.auth)

    return (
        <div className="dashboard">
        <section className="heading">
            <img src={logo} alt="logo"  width="200px"></img>
            <h1>Welcome to Developing Mental Toughness</h1>
            </section>
            {user ? (
            // Logged in user
            <>
            <Link to='/home' className="btn" style={{backgroundColor: "#007b60"}} id="enter-btn">
            <FaDoorOpen /> Enter
            </Link>
            </>) 
            : (
            //  Logged out user display   
            <>
            <Link to='/login' className="btn" style={{backgroundColor: "#007b60"}} id="enter-btn">
            <FaSignInAlt /> Log In
                </Link>
            </>)}
            </div>
    )
}

export default Dashboard