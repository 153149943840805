import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";
import { Link } from 'react-router-dom';

function ModuleSevenC() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Goal Orientation"
                time="10 Minutes"
                exercise={false}
                points={["Introduction to Goal Orientation", "How to use the exercises.", "A number of exercises to improve your Goal Orientation."]}
            />

            <div className="body-container">
                <div className="video-container">
                    {/* Insert links to videos below */}
                    <YoutubeEmbed embedId="DMeTDPOT0VA" />

                    <div className="exercises-container">
                        <h2>Exercises</h2>
                        <ul>
                            <li><Link to="/scent-of-lemon">The Scent Of A Lemon</Link></li>
                            <li><Link to="/what-will-i-do">What Will I Do Tomorrow?</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="menu-arrow-container">
                    {/* Links to next and previous modules, if none put {null} */}
                    <Arrows next="/module-7d" previous="/module-7b" />

                    {/* Update menu in IntroMenu.jsx */}
                    <IntroMenu />
                </div>
            </div>

        </div>
    )
}

export default ModuleSevenC
