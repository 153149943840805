import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";

function ModuleTwo() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Understanding Stress"
                time="10 Minutes"
                exercise={false}
                points={["Understanding Stress, Pressure and Challenge", 
                "Symptoms of Stress, Pressure and Challenge", 
                "In this module, we look at how we respond to stress, why we respond in that way and the symptoms that appear when under stress, pressure and challenge."]}
            />

            <div className="body-container">
                <div className="video-container">
                    {/* Insert links to videos below */}
                    <YoutubeEmbed embedId="P7LD61DIvEs" />
                    <YoutubeEmbed embedId="-RVYUl7Mr84" />
                </div>

                <div className="menu-arrow-container">
                    {/* Links to next and previous modules, if none put {null} */}
                    <Arrows next="/module-3" previous="/module-1" />

                    {/* Update menu in IntroMenu.jsx */}
                    <IntroMenu />
                </div>
            </div>

        </div>
    )
}

export default ModuleTwo
