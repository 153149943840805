import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";

function ModuleSix() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Your Pathway"
                time="3 Minutes"
                exercise={false}
                points={["Choosing your path.", "The Six Interventions.", "How each intervention develops Mental Toughness."]}
            />
            
            <div className="body-container">
            {/* Insert links to videos below */}
            <YoutubeEmbed embedId="rL2JVnGqyM0" />

            <div className="menu-arrow-container">
            {/* Links to next and previous modules, if none put {null} */}
            <Arrows next="/module-7a" previous="/module-5" />

            {/* Update menu in IntroMenu.jsx */}
            <IntroMenu />
            </div>
            </div>

        </div>
    )
}

export default ModuleSix
