import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";

function ModuleFourB() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="A Wider Look at Personality"
                time="10 Minutes"
                exercise={false}
                points={["Behaviours and issues for the Mentally tough.", 
                "Behaviours and issues for Mentally sensitive.", 
                "The impacts on others."]}
            />

            <div className="body-container">
                <div className="video-container">
                    {/* Insert links to videos below */}
                    <YoutubeEmbed embedId="X2tLau3SumM" />
                </div>

                <div className="menu-arrow-container">
                    {/* Links to next and previous modules, if none put {null} */}
                    <Arrows next="/module-5" previous="/module-4a" />

                    {/* Update menu in IntroMenu.jsx */}
                    <IntroMenu />
                </div>
            </div>

        </div>
    )
}

export default ModuleFourB
