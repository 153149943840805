import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";
import EmailButton from '../../components/ContactUs';

function Introduction() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Introduction"
                time="10 Minutes"
                exercise={false}
                points={["Video One: How this programme can help you develop resilience and positivity, understand your mindset, learn to optimise it, monitor outcomes and how these impact on behaviours.", 
                "Video Two: An overview of content, self guided flexible format & guidance on the modular pathway ", 
                "Video Three: Starting with the MTQPlus assessment, reflect and identify areas for development, 8 streams of development Intervention based on MT framework & monitoring and review"]}
            />

            <div className="body-container">
            <div className="video-container">
            {/* Insert links to videos below */}
            <EmailButton />
            <YoutubeEmbed embedId="6SuncI71MNU" />
            <YoutubeEmbed embedId="DAvLhXIZ7cE" />
            <YoutubeEmbed embedId="pfWVmREMq3k" />
            </div>

            <div className="menu-arrow-container">
            {/* Links to next and previous modules, if none put {null} */}
            <Arrows next="/module-1" previous={null} />

            {/* Update menu in IntroMenu.jsx */}
            <IntroMenu />
            </div>
            </div>

        </div>
    )
}

export default Introduction
