import "./Exercises.css"
import {Link} from 'react-router-dom'

export default function Exercises() {
    return (
        <div className="exercises">
            <h1>Exercises</h1>

            <h2 style={{color:"#007b60"}}>Control</h2>
            <Link to="/module-7a"><h3 className="control">Life Control</h3></Link>
            <Link to="/module-7b"><h3 className="control">Emotional Control</h3></Link>
            <hr class="solid"></hr>

            <h2 style={{color:"#7030a0"}}>Commitment</h2>
            <Link to="/module-7c"><h3 className="commitment">Goal Orientation</h3></Link>
            <Link to="/module-7d"><h3 className="commitment">Achievement Orientation</h3></Link>
            <hr class="solid"></hr>

            <h2 style={{color:"#4f81bd"}}>Challenge</h2>
            <Link to="/module-7e"><h3 className="challenge">Risk Orientation</h3></Link>
            <Link to="/module-7f"><h3 className="challenge">Learning Orientation</h3></Link>
            <hr class="solid"></hr>

            <h2 style={{color:"#ed364c"}}>Confidence</h2>
            <Link to="/module-7g"><h3 className="confidence">Confidence in Abilities</h3></Link>
            <Link to="/module-7h"><h3 className="confidence">Interpersonal Confidence</h3></Link>
        </div>
    )
}
