import "../introduction/styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Manual from "../../pdf/MuscularRelaxation.pdf"
import { FaBookOpen } from 'react-icons/fa'

function MuscularRelaxation() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Progressive Muscular Relaxation"
                time="5 Minutes"
                exercise={true}
                points={["An anxiety control exercise.", "Introducing a technique that helps release tension in one's body and mind.", "Video supported exercise."]}
            />

            <div className="body-container">
            <div className="video-container">
            {/* Insert links to videos below */}
            <YoutubeEmbed embedId="EWeij__NtF0" />
            </div>

            <div className="manual-container">
            <article>
                <h2>Manual</h2>
                <p>An editable manual to compliment this exercise. Available online or print out at home.</p>
                <br></br>
                </article>
                <a href={Manual} target="_blank" rel="noreferrer" className="btn" >
            <FaBookOpen /> Open
            </a>
            </div>
            
            </div>

        </div>
    )
}

export default MuscularRelaxation
