import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Dashboard from './pages/Dashboard'
import Register from './pages/Register'
import Login from './pages/Login'
import Goals from './pages/Goals'
import Header from './components/Header'
import Homepage from './pages/Homepage';
import Introduction from './pages/introduction/Introduction';
import ModuleOne from './pages/introduction/ModuleOne';
import ModuleTwo from './pages/introduction/ModuleTwo';
import ModuleThree from './pages/introduction/ModuleThree';
import ModuleFourA from './pages/introduction/ModuleFourA';
import ModuleFourB from './pages/introduction/ModuleFourB';
import ModuleFive from './pages/introduction/ModuleFive';
import ModuleSix from './pages/introduction/ModuleSix';
import ModuleSevenA from './pages/introduction/ModuleSevenA';
import Exercises from './pages/exercises/Exercises';
import ThinkThreePositives from './pages/exercises/ThinkThreePositives';
import ScentOfLemon from './pages/exercises/ScentOFLemon';
import ManagingTime from './pages/exercises/ManagingTime';
import GoalSetting from './pages/exercises/GoalSetting';
import AntsIntoPets from './pages/exercises/AntsIntoPets';
import ModuleSevenB from './pages/introduction/ModuleSevenB';
import ModuleSevenC from './pages/introduction/ModuleSevenC';
import ModuleSevenD from './pages/introduction/ModuleSevenD';
import ModuleSevenE from './pages/introduction/ModuleSevenE';
import ModuleSevenF from './pages/introduction/ModuleSevenF';
import ModuleSevenG from './pages/introduction/ModuleSevenG';
import ModuleSevenH from './pages/introduction/ModuleSevenH';
import ControlledBreathing from './pages/exercises/ControlledBreathing';
import WhatToDoTomorrow from './pages/exercises/WhatToDoTomorrow';
import MuscularRelaxation from './pages/exercises/MuscularRelaxation';
import RecognisingBlockers from './pages/exercises/RecognisingBlockers';
import EatingElephant from './pages/exercises/EatingElephant';
import MakingAffirmations from './pages/exercises/MakingAffirmations';
import ModuleEight from './pages/introduction/ModuleEight';

function App() {
  return (
    <>
    <Router>
    <div className="container">
      <Header />
      <Routes>
        <Route path='/' element={<Dashboard/>} />
        <Route path='/login' element={<Login/>} />
        <Route path='/register' element={<Register/>} />
        <Route path='/home' element={<Homepage />} />
        <Route path='/goals' element={<Goals/>} />

        <Route path='/introduction' element={<Introduction />} />
        <Route path='/module-1' element={<ModuleOne />} />
        <Route path='/module-2' element={<ModuleTwo />} />
        <Route path='/module-3' element={<ModuleThree />} />
        <Route path='/module-4a' element={<ModuleFourA />} />
        <Route path='/module-4b' element={<ModuleFourB />} />
        <Route path='/module-5' element={<ModuleFive />} />
        <Route path='/module-6' element={<ModuleSix />} />
        <Route path='/module-7a' element={<ModuleSevenA />} />
        <Route path='/module-7b' element={<ModuleSevenB />} />
        <Route path='/module-7c' element={<ModuleSevenC />} />
        <Route path='/module-7d' element={<ModuleSevenD />} />
        <Route path='/module-7e' element={<ModuleSevenE />} />
        <Route path='/module-7f' element={<ModuleSevenF />} />
        <Route path='/module-7g' element={<ModuleSevenG />} />
        <Route path='/module-7h' element={<ModuleSevenH />} />
        <Route path='/module-8' element={<ModuleEight />} />

        <Route path='/exercises' element={<Exercises />} />
        <Route path='/think-three-positives' element={<ThinkThreePositives />} />
        <Route path='/scent-of-lemon' element={<ScentOfLemon />} />
        <Route path='/managing-time' element={<ManagingTime />} />
        <Route path='/goal-setting' element={<GoalSetting />} />
        <Route path='/ants-into-pets' element={<AntsIntoPets />} />
        <Route path='/controlled-breathing' element={<ControlledBreathing />} />
        <Route path='/what-will-i-do' element={<WhatToDoTomorrow />} />
        <Route path='/muscular-relaxation' element={<MuscularRelaxation />} />
        <Route path='/eating-elephant' element={<EatingElephant />} />
        <Route path='/recognising-blockers' element={<RecognisingBlockers />} />
        <Route path='/making-affirmations' element={<MakingAffirmations />} />

      </Routes> 
    </div>
    </Router>
    <ToastContainer />
    </>
  );
}

export default App;
