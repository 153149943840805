import "../introduction/styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Manual from "../../pdf/ControlledBreathing.pdf"
import { FaBookOpen } from 'react-icons/fa'

function ControlledBreathing() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Controlled Breathing"
                time="5 Minutes"
                exercise={true}
                points={["An anxiety control exercise.", "A guided process to help you to breath at your full capacity reducing anxiety levels.", "The aim of this exercise is to help an individual to become more positive and learn to cope better with work and life challenges."]}
            />

            <div className="body-container">
            <div className="video-container">
            {/* Insert links to videos below */}
            <YoutubeEmbed embedId="HFh0VsjEaVg" />
            </div>

            <div className="manual-container">
            <article>
                <h2>Manual</h2>
                <p>An editable manual to compliment this exercise. Available online or print out at home.</p>
                <br></br>
                </article>
                <a href={Manual} target="_blank" rel="noreferrer" className="btn" >
            <FaBookOpen /> Open
            </a>
            </div>
            
            </div>

        </div>
    )
}

export default ControlledBreathing
