import React from 'react'
import './Homepage.css'
import {Link} from 'react-router-dom'
import { FaFlagCheckered, FaMapMarked, FaBookOpen } from 'react-icons/fa'
import MTDManual from "../images/MTDP Workbook Main.pdf"

function Homepage() {
    return (
        <div className="homepage">
            <section className="blocks">
            <section className="home-block">
                <article>
                <h2>Introduction</h2>
                <p>A set of modules designed to teach you about the mental toughness concept, enabling the self-awareness to pin point and develop your own mental toughness.</p>
                </article>
                <Link to='/introduction' className="btn" id="block-button">
            <FaFlagCheckered /> Start
                </Link>
            </section>
            <section className="home-block">
            <article>
                <h2>Exercises</h2>
                <p>An array of exercises, each designed to develop one or more of the 8 factors that make up your mental toughness.</p>
                </article>
                <Link to='/exercises' className="btn" id="block-button" style={{backgroundColor:"#cd364c"}}>
            <FaMapMarked /> Explore
                </Link>
            </section>
            <section className="home-block">
            <article>
                <h2>Workbook</h2>
                <p>An editable workbook to compliment the introduction videos and exercises in the programme. Available online or print out at home.</p>
                </article>
                <a href={MTDManual} target="_blank" rel="noreferrer" className="btn" id="block-button" style={{backgroundColor:"#4f81bd"}}>
            <FaBookOpen /> Open
            </a>
            </section>
            </section>
        </div>
    )
}

export default Homepage
