import React from 'react';

function EmailButton() {
  const handleEmailClick = () => {
    const emailBody = 'I am currently undertaking the Mental Toughness Development Programme and would like to assess my Mental Toughness using the MTQPlus. Please send me an invitation to complete the assessment.';
    window.location.href = `mailto:headoffice@aqr.co.uk?subject=MTQPlus Test&body=${encodeURIComponent(emailBody)}`;
  };

  const buttonStyle = {
    backgroundColor: '#cd364c',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '8px 16px',
    cursor: 'pointer',
    fontSize: "25px"
  };

  return (
    <button style={buttonStyle} onClick={handleEmailClick}>
      Request an MTQPlus Assessment
    </button>
  );
}

export default EmailButton;