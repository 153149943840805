import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";
import { Link } from 'react-router-dom';

function ModuleSevenD() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Achievement Orientation"
                time="10 Minutes"
                exercise={false}
                points={["Introduction to Achievement Orientation", "How to use the exercises.", "A number of exercises to improve your Achievement Orientation."]}
            />

            <div className="body-container">
                <div className="video-container">
                    {/* Insert links to videos below */}
                    <YoutubeEmbed embedId="iTiRcU8lE4o" />

                    <div className="exercises-container">
                        <h2>Exercises</h2>
                        <ul>
                            <li><Link to="/eating-elephant">Eating the Elephant</Link></li>
                            <li><Link to="/what-will-i-do">What Will I Do Tomorrow?</Link></li>
                            <li><Link to="/recognising-blockers">Recognising Your Blockers</Link></li>
                            <li><Link to="/managing-time">Managing My Time</Link></li>
                            <li><a target='_blank' rel='noopener noreferrer' href="https://numbergrid-6b581.web.app">Number Grid</a></li>

                        </ul>
                    </div>
                </div>

                <div className="menu-arrow-container">
                    {/* Links to next and previous modules, if none put {null} */}
                    <Arrows next="/module-7e" previous="/module-7c" />

                    {/* Update menu in IntroMenu.jsx */}
                    <IntroMenu />
                </div>
            </div>

        </div>
    )
}

export default ModuleSevenD
