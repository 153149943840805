import "../introduction/styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";

function ScentOfLemon() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Scent of a Lemon"
                time="5 Minutes"
                exercise={true}
                points={["Visualisation exercise.", "Introducing a technique that helps you to accomplish your goals.", "Video supported exercise."]}
            />

            {/* Insert links to videos below */}
            <YoutubeEmbed embedId="5EtV5fVQmno" />

        </div>
    )
}

export default ScentOfLemon
