import React from 'react'

function Goals() {
    return (
        <div>
            Goals
        </div>
    )
}

export default Goals
