import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";

function ModuleOne() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="We Start With You"
                time="12 Minutes"
                exercise={false}
                points={["Goals of the programme and how to set them", 
                "Starting with you and what you want to achieve. Focusing on: the present, the future & engagement with others ",
                "Think about outcomes important to you. How to set SMART Goals, links to Goal Orientation & force field analysis"]}
            />

            <div className="body-container">
                <div className="video-container">
                {/* Insert links to videos below */}
                <YoutubeEmbed embedId="2zlpdSLCiWI" />
                <YoutubeEmbed embedId="j9WnOW9pKpU" />
            </div>

            <div className="menu-arrow-container">
                {/* Links to next and previous modules, if none put {null} */}
                <Arrows next="/module-2" previous="/introduction" />

                {/* Update menu in IntroMenu.jsx */}
                <IntroMenu />
            </div>
        </div>

        </div >
    )
}

export default ModuleOne
