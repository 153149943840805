import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";
import { Link } from 'react-router-dom';

function ModuleSevenG() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Confidence in Abilities"
                time="10 Minutes"
                exercise={false}
                points={["Introduction to Confidence in Abilities", "How to use the exercises.", "A number of exercises to improve your Confidence in Abilities."]}
            />

            <div className="body-container">
                <div className="video-container">
                    {/* Insert links to videos below */}
                    <YoutubeEmbed embedId="SFO5NCRnKi4" />

                    <div className="exercises-container">
                        <h2>Exercises</h2>
                        <ul>
                            <li><Link to="/making-affirmations">Making Affirmations</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="menu-arrow-container">
                    {/* Links to next and previous modules, if none put {null} */}
                    <Arrows next="/module-7h" previous="/module-7f" />

                    {/* Update menu in IntroMenu.jsx */}
                    <IntroMenu />
                </div>
            </div>

        </div>
    )
}

export default ModuleSevenG
