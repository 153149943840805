import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";

function ModuleThree() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="What is Mental Toughness"
                time="6 Minutes"
                exercise={false}
                points={["An overview of the eight factor model.", 
                "Resilience and positivity components.", 
                "Mental Toughness profile and its link to the programme structure."]}
            />

            <div className="body-container">
                <div className="video-container">
                    {/* Insert links to videos below */}
                    <YoutubeEmbed embedId="8DZaLEUMPqg" />
                </div>

                <div className="menu-arrow-container">
                    {/* Links to next and previous modules, if none put {null} */}
                    <Arrows next="/module-4a" previous="/module-2" />

                    {/* Update menu in IntroMenu.jsx */}
                    <IntroMenu />
                </div>
            </div>

        </div>
    )
}

export default ModuleThree
