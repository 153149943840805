import {Link} from "react-router-dom"

function ScrollToTop() {
    window.scrollTo(0, 0)
  }

const IntroMenu = () => (
    <div className="menu-container">
    <Link onClick={ScrollToTop} to="/introduction">Introduction</Link>
    <Link onClick={ScrollToTop} to="/module-1">1. We Start With You</Link>
    <Link onClick={ScrollToTop} to="/module-2">2. Understanding Stress</Link>
    <Link onClick={ScrollToTop} to="/module-3">3. What is Mental Toughness</Link>
    <Link onClick={ScrollToTop} to="/module-4a">4. Where Am I Now? MTQ</Link>
    <Link onClick={ScrollToTop} to="/module-4b">5. A Wider Look at Personality</Link>
    <Link onClick={ScrollToTop} to="/module-5">6. Reflection</Link>
    <Link onClick={ScrollToTop} to="/module-6">7. Intro to the MTD Pathway</Link>
    <Link onClick={ScrollToTop} to="/module-7a">8. Life Control</Link>
    <Link onClick={ScrollToTop} to="/module-7b">9. Emotional Control</Link>
    <Link onClick={ScrollToTop} to="/module-7c">10. Goal Orientation</Link>
    <Link onClick={ScrollToTop} to="/module-7d">11. Achievement Orientation</Link>
    <Link onClick={ScrollToTop} to="/module-7e">12. Risk Orientation</Link>
    <Link onClick={ScrollToTop} to="/module-7f">13. Learning Orientation</Link>
    <Link onClick={ScrollToTop} to="/module-7g">14. Confidence in Abilities</Link>
    <Link onClick={ScrollToTop} to="/module-7h">15. Interpersonal Confidence</Link>
    <Link onClick={ScrollToTop} to="/module-8">16. Next Steps</Link>
</div>
);

export default IntroMenu;