import React from "react";

const PageHeader = ({ title, time, points, exercise }) => (
  <div id="page-header">
      {/* <img className="background-image" alt="background" src={background}></img> */}
    <div className="module-background">
                <article className="heading">
                    <h1>{title}</h1>
                    <p>{time}</p>
                </article>
                <h3 className="module-points-header">What is included in this {(exercise ? "exercise" : "module")}?</h3>
                <ul className="module-points">
                    <li>{points[0]}</li>
                    <li>{points[1]}</li>
                    <li>{points[2]}</li>
                </ul>
            </div>
  </div>
);

export default PageHeader;